import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Attachments, CreateIncidentActivityDTO } from 'src/modules/incidents/application/dto/CreateIncidentActivityDTO'
import { IncidentActivityDTO } from 'src/modules/incidents/application/dto/IncidentActivityDTO'
import { IncidentDetailClosedDTO, IncidentDetailDTO } from 'src/modules/incidents/application/dto/IncidentDetailDto'
import { useFindFTTHIncidentQuery } from 'src/modules/incidents/application/FindFTTHIncidentQueries'
import {
	useCreateIncidentActivityQuery,
	useFindIncidentActivitiesQuery,
} from 'src/modules/incidents/application/IncidentActivityQueries'
import { responseToDto } from 'src/modules/incidents/infrastructure/FTTHIncident'
import { HttpIncidentRepository } from 'src/modules/incidents/infrastructure/HttpIncidentRepository'
import { AuthContext, useLang, useTranslator } from 'ufinet-web-functions'

import { Loading, UfinetBox } from 'ufinet-web-components'
import {
	DetailTicketClosed,
	DetailTicketIncidentInfo,
	DetailTicketNotes,
	DetailTicketServiceInfo,
	DetailTicketVisitInfo,
} from './components'

const setInternalExternalId = (incident: IncidentDetailDTO) => {
	return {
		incidentId: incident.externalId ? incident.externalId : null,
		externalId: incident.externalId ? null : incident.incidentId,
	}
}

const FTTHTicketDetailPage: FC = () => {
	const authData = useContext(AuthContext)
	const [incident, setIncident] = useState<IncidentDetailDTO>({} as IncidentDetailDTO)
	const [incidentClosed, setIncidentClosed] = useState<IncidentDetailClosedDTO>({} as IncidentDetailClosedDTO)
	const { id } = useParams<{ id: string }>()
	const lang = useLang()
	const translate = useTranslator()
	const incidentRepository = useMemo(() => HttpIncidentRepository(authData), [authData])
	const [activities, setActivities] = useState<IncidentActivityDTO[]>([])
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const rowsPerPage = 50

	const { mutate: findFTTHIncident, isLoading: loadingIncident } = useFindFTTHIncidentQuery(incidentRepository, {
		onSuccess: (response) => {
			const parsedResponse = responseToDto(response, lang)
			setIncident(parsedResponse.incidentDetail)
			setIncidentClosed(parsedResponse.incidentDetailClosed)
		},
		onError: () => {
			toast.error(translate('ERROR.DETAIL_TICKET.INCIDENT'))
		},
	})

	useEffect(() => {
		if (id) {
			findFTTHIncident(id)
		}
	}, [id])

	const { refetch: fetchIncidentActivities, isFetching: isLoadingActivities } = useFindIncidentActivitiesQuery(
		incidentRepository,
		{
			...setInternalExternalId(incident),
			pageNumber: 1,
			rowsPerPage,
		},
		{
			onSuccess: (response) => {
				if (response.status === 204) {
					setActivities([])
					setTotalPages(1)
				} else {
					setActivities(response.data)
					setTotalPages(Math.ceil(response.data.length / 5))
					setCurrentPage(1)
				}
			},
			onError: () => {
				toast.error(translate('ERROR.FETCHING_ACTIVITIES'))
			},
		}
	)

	useEffect(() => {
		if (incident.incidentId) {
			fetchIncidentActivities()
		}
	}, [incident])

	const { mutate: createIncidentActivity, isLoading: isCreatingIncidentActivity } = useCreateIncidentActivityQuery(
		incidentRepository,
		{
			onSuccess: () => {
				toast.success(translate('TICKET_DETAILS.ACTIVITY_CREATED_SUCCESS'))
				fetchIncidentActivities()
			},
			onError: () => {
				toast.error(translate('TICKET_DETAILS.ACTIVITY_CREATED_ERROR'))
			},
		}
	)

	const handleCreateIncidentActivity = async (subject: string, description: string, attachments?: Attachments) => {
		if (!id) return

		const newActivity: CreateIncidentActivityDTO = {
			...setInternalExternalId(incident),
			subject,
			description,
			attachments: (attachments ?? []).length > 0 ? attachments : undefined,
		}

		createIncidentActivity(newActivity)
	}

	const handlePageChange = (newPage: number) => {
		if (newPage !== currentPage) {
			setCurrentPage(newPage)
		}
	}

	if (loadingIncident) {
		return (
			<UfinetBox className="detail-loading-container">
				<Loading userClasses="detail-loading" />
			</UfinetBox>
		)
	}

	return (
		<UfinetBox>
			<div>
				<DetailTicketIncidentInfo
					isCorporate
					ticketName={incident.ticketName}
					ticketId={incident.ticketId}
					creationDate={incident.creationDate}
					ticketStatus={incident.ticketStatus}
					lastStatus={incident.lastStatus}
					clientName={incident.customerName}
					reportType={incident.reportType}
					degradationType={incident.degradationType}
					incidentDescription={incident.incidentDescription}
					reportOrigin={incident.reportOrigin}
					clientTicketNumber={incident.customerTicketNumber}
					reportUser={incident.reportUser}
					notificationGroup={incident.notificationGroup}
				/>

				<DetailTicketServiceInfo
					administrativeCode={incident.administrativeCode}
					originCountryName={incident.originCountryName}
					destinationCountryName={incident.destinationCountryName}
					serviceType={incident.serviceType}
					transmissionMedium={incident.transmissionMedium}
					bandwidthCapacity={incident.bandwidthCapacity}
					vlanClient={incident.vlanClient}
					publicIp={incident.publicIp}
				/>

				{(incident.ticketStatusId === 912790005 || incident.ticketStatusId === 4) && (
					<DetailTicketClosed
						resetDate={incidentClosed.resetDate}
						closeDate={incidentClosed.closeDate}
						solutionType={incidentClosed.solutionType}
						primaryCause={incidentClosed.primaryCause}
						secondaryCause={incidentClosed.secondaryCause}
						confirmedWith={incidentClosed.confirmedWith}
						latitudeLongitudeFailure={incidentClosed.latitudeLongitudeFailure}
						additionalComments={incidentClosed.additionalComments}
						attributableTo={incidentClosed.attributableTo}
						discountTime={incidentClosed.discountTime}
						discountReason={incidentClosed.discountReason}
						rfoSentDate={incidentClosed.rfoSentDate}
						rfoSummary={incidentClosed.rfoSummary}
					/>
				)}

				<DetailTicketVisitInfo
					visitDate={incident.visitDate}
					visitContactName={incident.visitContactName}
					visitContactPhone={incident.visitContactPhone}
					visitRequirements={incident.visitRequirements}
					ticketStatusId={incident.ticketStatusId}
					incidentId={incident.incidentId}
					onCreateComment={() => fetchIncidentActivities()}
					internalExternalId={setInternalExternalId(incident)}
					isCreatingIncidentActivity={isLoadingActivities}
				/>

				<DetailTicketNotes
					activities={activities}
					loading={isLoadingActivities}
					currentPage={currentPage}
					ticketStatusId={incident.ticketStatusId}
					onPageChange={handlePageChange}
					totalPages={totalPages}
					onCreateComment={handleCreateIncidentActivity}
					isTicketInProcess={!incident.ticketId}
					isCreatingIncidentActivity={isCreatingIncidentActivity}
				/>
			</div>
		</UfinetBox>
	)
}

export { FTTHTicketDetailPage }

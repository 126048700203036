import {
  defaultOptionsFetch,
  FetchAPI,
  FetchOptions,
  IAuthData
} from 'ufinet-web-functions';

import { AttachmentConfig } from '../domain/AttachmentConfig';
import { AttachmentConfigRepository } from '../domain/repository/AttachmentConfigRepository';

export const HttpAttachmentConfigRepository: (authData: IAuthData) => AttachmentConfigRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}attachmentConfig`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<AttachmentConfig[]> =>
			await FetchAPI.get(`${url}/GetAttachmentConfig`, {
				...fetchOptions,
				...options,
			}),
	}
}

import { ServicesCustomerDTO } from 'src/modules/servicesCustomer/application/dto/ServicesCustomerDTO'
import { convertToLocalTimeString } from 'src/utils/dateUtils'
import { IUfinetSelectOption } from 'ufinet-web-functions'

import { FtthIncident } from '../../domain/FtthIncident'

export class FtthIncidentSaveRequest {
	ticket: FtthIncident

	constructor(ticket: FtthIncident) {
		this.ticket = ticket
	}

	static fromValues(obj: {
		reportContactId: IUfinetSelectOption
		affectedTypeId: IUfinetSelectOption<number>
		clientSelect: ServicesCustomerDTO
		creationDate: Date
		degradationType: IUfinetSelectOption<number>
		detectedDate: Date
		incidentDescription: string
		internalCustomerTicketNumber: string
		networkElementAffected: string
		networkElementTypeId: IUfinetSelectOption<number>
		notificationGroupId: IUfinetSelectOption
		notificationLanguageId: IUfinetSelectOption<number>
		affectedService: ServicesCustomerDTO
		reportClassification: IUfinetSelectOption<number>
		reportContactEmail: string
		reportContactPhone: string
		reportType: IUfinetSelectOption<number>
		visitContactName: string
		visitContactPhone: string
		visitRequirements: string
		visitDate: Date
		emailUserApplication: string
	}) {
		const ticket: FtthIncident = {
			typeId: 912790000,
			reportOriginId: 3,
			serviceAffectedId: obj.affectedService.value,
			administrativeCode: obj.affectedService.name || undefined,
			customerId: obj.clientSelect.customerId,
			reportType: obj.reportType.value,
			degradationTypeId: obj.degradationType.value || undefined,
			reportContactId: obj.reportContactId.value,
			reportContactEmail: obj.reportContactEmail,
			reportContactPhone: obj.reportContactPhone,
			detectedDate: convertToLocalTimeString(obj.detectedDate),
			notificationGroupId: obj.notificationGroupId.value || undefined,
			notificationLanguageId: obj.notificationLanguageId.value,
			description: obj.incidentDescription,
			visitingHours: convertToLocalTimeString(obj.visitDate),
			visitContactName: obj.visitContactName || undefined,
			visitContactPhone: obj.visitContactPhone || undefined,
			visitRequirements: obj.visitRequirements || undefined,
			affectedTypeId: obj.affectedTypeId.value,
			networkElementTypeId: obj.networkElementTypeId.value || undefined,
			networkElementAffected: obj.networkElementAffected || undefined,
			reportClassification: obj.reportClassification.value || undefined,
			internalCustomerTicketNumber: obj.internalCustomerTicketNumber || undefined,
			emailUserApplication: obj.emailUserApplication,
		}
		return new FtthIncidentSaveRequest(ticket)
	}
}

import { useMutation } from 'react-query'

import { IncidentRepository } from '../domain/repository/IncidentRepository'
import { IncidentVisitSaveRequest } from './dto/IncidentVisitSaveRequest'

export const useSaveIncidentVisitMutation = (
	incidentRepository: IncidentRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: (error: unknown) => void
	}
) => {
	return useMutation(
		(request: { visit: IncidentVisitSaveRequest; incidentId: string }) =>
			incidentRepository.saveIncidentVisit(request.visit, request.incidentId),
		{
			onSuccess,
			onError,
			mutationKey: 'save-incident-visit',
		}
	)
}

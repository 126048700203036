import { formatDate } from 'src/utils/dateUtils'
import { getLocalizedName } from 'src/utils/localizedName'
import { AppLanguage } from 'ufinet-web-functions'

import { CorporativeIncidentDTO } from '../application/dto/CorporativeIncidentDto'
import { CorporativeIncidentFindResponse } from '../application/dto/CorporativeIncidentFindResponse'

export const responseToDto = (response: CorporativeIncidentFindResponse, lang: AppLanguage): CorporativeIncidentDTO => {
	return {
		incidentDetail: incidentDetail(response, lang),
		incidentDetailClosed: incidentDetailClosed(response, lang),
	}
}

const incidentDetail = (response: CorporativeIncidentFindResponse, lang: AppLanguage) => {
	return {
		incidentId: response.incidentId,
		externalId: response.externalId,
		ticketName: response.ticketName,
		ticketId: response.ticketId,
		customerName: response.customerName,
		creationDate: formatDate(response.createdDate, '-', true),
		ticketStatus: getLocalizedName(response.processStatus, lang),
		ticketStatusId: response.processStatus.processStatusId,
		lastStatus: formatDate(response.modifiedDate, '-', true),
		clientId: response.customerId,
		reportType: getLocalizedName(response.reportType, lang),
		degradationType: getLocalizedName(response.degradationType, lang),
		incidentDescription: response.description,
		reportOrigin: getLocalizedName(response.reportOrigin, lang),
		customerTicketNumber: response.internalCustomerTicketNumber,
		reportUser: response.reportContactEmail,
		notificationGroup: response.notificationGroup.name,
		visitDate: formatDate(response.visitingHours, '-', true),
		visitContactName: response.visitContactName,
		visitContactPhone: response.visitContactPhone,
		visitRequirements: response.visitRequirements,
		administrativeCode: response.administrativeCode,
		serviceType: getLocalizedName(response.serviceType, lang),
		bandwidthCapacity: response.bandwidthCapacity,
		originCountryName: response.originCountryName,
		destinationCountryName: response.destinationCountryName,
		incidentTitle: response.incidentTitle,
		transmissionMedium: getLocalizedName(response.transmissionMedium, lang),
		vlanClient: response.vlanClient,
		publicIp: response.publicIp,
	}
}

const incidentDetailClosed = (response: CorporativeIncidentFindResponse, lang: AppLanguage) => {
	return {
		resetDate: formatDate(response.serviceRestorationDate, '/', true),
		closeDate: formatDate(response.statusCloseDate, '/', true),
		solutionType: getLocalizedName(response.solutionType, lang),
		primaryCause: getLocalizedName(response.primaryCause, lang),
		secondaryCause: getLocalizedName(response.secondaryCause, lang),
		confirmedWith: response.confirmedWith,
		latitudeLongitudeFailure: response.latitudeFailure
			? `${response.latitudeFailure}, ${response.longitudeFailure}`
			: '',
		rfoSummary: response.summaryRfo,
		rfoSentDate: formatDate(response.rfoSentDate, '/', true),
		additionalComments: response.additionalComments,
		attributableTo: getLocalizedName(response.attributableTo, lang),
		discountTime: response.totalDiscountTime,
		discountReason: response.descriptionDiscountTime,
	}
}

import * as ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { GtiI18nProvider } from '@Components/common/i18n/GtiI18nProvider'
import { AppRoutes } from '@Routing/AppRoutes'
import { worker } from 'src/mocks/browser'

import { AuthProvider } from 'ufinet-web-components'
import packageJson from '../../package.json'

import 'ufinet-web-components/dist/index.css'
import '../sass/style.scss'

// Start the mocking conditionally
if (process.env.VITE_ENV === 'TEST') {
	worker.start()
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: true,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: false,
		},
	},
})

console.info(packageJson.version)

ReactDOM.render(
	<>
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<GtiI18nProvider>
					<AppRoutes />
				</GtiI18nProvider>
			</AuthProvider>
		</QueryClientProvider>
	</>,
	document.getElementById('root')
)

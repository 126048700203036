import { FC, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormikProps, useFormik } from 'formik'
import { useSaveFtthIncidentMutation } from 'src/modules/incidents/application/SaveCorporateIncidentQueries'
import { HttpIncidentRepository } from 'src/modules/incidents/infrastructure/HttpIncidentRepository'
import { ServicesCustomerDTO } from 'src/modules/servicesCustomer/application/dto/ServicesCustomerDTO'
import { AFFECTED_TYPES_IDS, REPORT_TYPES_IDS } from 'src/utils/constants'
import { AuthContext, emptyUfinetSelectOption, IUfinetSelectOption, useLang, useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'

import { ContactInfoSection } from '@Pages/tickets/new/components/ContactInfoSection'
import { IncidentInfoSection } from '@Pages/tickets/new/components/IncidentInfoSection'
import { UfinetBox } from 'ufinet-web-components'
import { FtthIncidentSaveRequest } from '../../../modules/incidents/application/dto/FtthIncidentSaveRequest'
import { useReportTypeFindAllQuery } from '../../../modules/reportTypes/application/ReportTypeQueries'
import { ReportType } from '../../../modules/reportTypes/domain/ReportType'
import { HttpReportTypeRepository } from '../../../modules/reportTypes/infrastructure/HttpReportTypeRepository'
import { ActionButtons } from './components/ActionButtons'
import { TicketInfoSection } from './components/TicketInfoSection'
import { CreateFtthIncidentFormikType } from './CreateIncidentFormikType'

const CreateFtthIncidentPage: FC = () => {
	const navigate = useNavigate()
	const translate = useTranslator()
	const authData = useContext(AuthContext)
	const nowDate = useMemo(() => new Date(), [])
	const language = useLang()

	const incidentRepository = useMemo(() => HttpIncidentRepository(authData), [authData])
	const reportTypeRepository = useMemo(() => HttpReportTypeRepository(authData), [authData])

	const [reportTypes, setReportTypes] = useState<IUfinetSelectOption[]>([])
	const [selectedReportType, setSelectedReportType] = useState<IUfinetSelectOption>()
	const [selectedAffectedType, setSelectedAffectedType] = useState<IUfinetSelectOption>()

	const emptyNumberUfinetSelectOption: IUfinetSelectOption<number> = {
		label: '',
		value: 0,
	}

	const { isLoading: loadingReportTypes } = useReportTypeFindAllQuery(reportTypeRepository, {
		onSuccess: (params: ReportType[]): void => {
			const mappedData = params.map((item) => ReportType.mapReportTypeToSelectOption(item, language))
			setReportTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.REPORT_TYPE')),
	})

	const getDegradationTypeSchemaValidation: () => Yup.ObjectSchema<
		{ label: string | null | undefined } | null,
		Yup.AnyObject,
		{ label: undefined } | null,
		''
	> = () =>
		selectedReportType?.value == REPORT_TYPES_IDS.DEGRADATION.toString()
			? Yup.object()
					.nullable()
					.shape({
						label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
					})
			: Yup.object().nullable().shape({
					label: Yup.string().nullable(),
				})

	const getServiceIdSchemaValidation: () => Yup.Schema<string | null | undefined> = () =>
		selectedAffectedType?.value.toString() === AFFECTED_TYPES_IDS.CLIENT_SERVICE.toString()
			? Yup.string().required(translate('ERROR.REQUIRED'))
			: Yup.string().nullable()

	const validationSchema = Yup.object().shape({
		reportContactId: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		affectedTypeId: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		clientSelect: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		notificationLanguageId: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		serviceId: getServiceIdSchemaValidation(),
		internalCustomerTicketNumber: Yup.string().max(200, translate('ERROR.MAX_LENGTH')),
		reportType: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		affectedService: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		degradationType: getDegradationTypeSchemaValidation(),
		detectedDate: Yup.string().required(translate('ERROR.REQUIRED')),
		incidentDescription: Yup.string().required(translate('ERROR.REQUIRED')),
		reportContactEmail: Yup.string().required(translate('ERROR.REQUIRED')),
		reportContactPhone: Yup.string()
			.required(translate('ERROR.REQUIRED'))
			.matches(/^\+?(\d{1,3})?[-. (]*\d{1,4}[-. )]*(\d{1,4}[-. ]*){1,4}\d{1,4}$/, translate('ERROR.PHONE')),
	})

	const { mutate, isLoading: isDisableButton } = useSaveFtthIncidentMutation(incidentRepository, {
		onSuccess: () => {
			toast.success(translate('TICKET.SAVE.SUCCESS'))
			navigate('/list-tickets')
		},
		onError: () => {
			toast.warning(translate('TICKET.SAVE.ERROR'))
		},
	})

	const initialValues = useMemo(
		() => ({
			reportContactId: emptyUfinetSelectOption,
			affectedTypeId: emptyNumberUfinetSelectOption,
			clientSelect: emptyUfinetSelectOption as ServicesCustomerDTO,
			creationDate: nowDate,
			degradationType: emptyNumberUfinetSelectOption,
			detectedDate: undefined as unknown as Date,
			incidentDescription: '',
			internalCustomerTicketNumber: '',
			networkElementAffected: '',
			networkElementTypeId: emptyNumberUfinetSelectOption,
			notificationGroupId: emptyUfinetSelectOption,
			notificationLanguageId: emptyNumberUfinetSelectOption,
			affectedService: emptyUfinetSelectOption as ServicesCustomerDTO,
			reportClassification: emptyNumberUfinetSelectOption,
			reportContactEmail: authData.userData?.username || '',
			reportContactPhone: '',
			reportType: emptyNumberUfinetSelectOption,
			serviceId: '',
			visitContactName: '',
			visitContactPhone: '',
			visitRequirements: '',
			visitDate: undefined as unknown as Date,
			emailUserApplication: authData.userData?.username || '',
		}),
		[authData.userData]
	)

	const formik: FormikProps<CreateFtthIncidentFormikType> = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: () => mutate(FtthIncidentSaveRequest.fromValues(formik.values)),
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
	})

	return (
		<UfinetBox>
			<div>
				<TicketInfoSection formik={formik} />

				<IncidentInfoSection
					formik={formik}
					reportTypes={reportTypes}
					loadingReportTypes={loadingReportTypes}
					setSelectedReportType={setSelectedReportType}
					setSelectedAffectedType={setSelectedAffectedType}
				/>

				<ContactInfoSection formik={formik} />

				<ActionButtons onSubmit={formik.handleSubmit} isDisableButton={isDisableButton} />
			</div>
		</UfinetBox>
	)
}

export { CreateFtthIncidentPage }

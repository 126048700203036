import { useMutation } from 'react-query'

import { NotificationGroup } from '../domain/NotificationGroup'
import { NotificationGroupRepository } from '../domain/repository/NotificationGroupRepository'

export const useNotificationGroupFindAllQuery = (
	notificationGroupRepository: NotificationGroupRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (notificationGroup: NotificationGroup[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useMutation(
		(request: { corporativeGroupId: string; customerId: string }) =>
			notificationGroupRepository.findAll(request.corporativeGroupId, request.customerId),
		{
			onSuccess,
			onError,
			mutationKey: 'notification-group-find-all-query',
		}
	)
}

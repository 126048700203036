import { FC, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AuthContext, Authority, useTranslator } from 'ufinet-web-functions'

import { UfinetBox, UfinetButton, UfinetSectionBox } from 'ufinet-web-components'

const CreateIncidentPage: FC = () => {
	const { userData } = useContext(AuthContext)
	const navigate = useNavigate()
	const translate = useTranslator()

	const roles = userData?.authorities || []
	const corporatePermissions = Authority.getGtiCorporatePermissions(roles)
	const ftthPermissions = Authority.getGtiFtthPermissions(roles)

	const handleNavigation = () => {
		if (corporatePermissions.canWrite && !ftthPermissions.canWrite) {
			navigate('/new-ticket-corp')
		} else if (ftthPermissions.canWrite && !corporatePermissions.canWrite) {
			navigate('/new-ticket-ftth')
		}
	}

	useEffect(() => {
		handleNavigation()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (corporatePermissions.canWrite && ftthPermissions.canWrite) {
		return (
			<UfinetBox>
				<UfinetSectionBox className="text-center">
					<h4>{translate('TICKET.NEW.CHOOSE')}</h4>
					<div>
						<UfinetButton
							content={translate('TICKET.NEW.CORPORATE')}
							className="m-5"
							onClick={() => navigate('/new-ticket-corp')}
						/>
						<UfinetButton
							content={translate('TICKET.NEW.FTTH')}
							className="m-5"
							onClick={() => navigate('/new-ticket-ftth')}
						/>
					</div>
				</UfinetSectionBox>
			</UfinetBox>
		)
	}

	return null
}

export { CreateIncidentPage }

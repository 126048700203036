import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTranslator } from 'ufinet-web-functions'

import { UfinetButton, UfinetSectionBox } from 'ufinet-web-components'

interface ActionButtonsProps {
	onSubmit: () => void
	isDisableButton: boolean
}

const ActionButtons: FC<ActionButtonsProps> = ({ onSubmit, isDisableButton }) => {
	const translate = useTranslator()
	const navigate = useNavigate()

	return (
		<UfinetSectionBox className="p-5">
			<div className="d-flex gap-9 flex-center">
				<UfinetButton
					content={translate('BUTTON.SEND')}
					type="button"
					onClick={onSubmit}
					isDisabled={isDisableButton}
				/>
				<UfinetButton content={translate('BUTTON.CANCEL')} onClick={() => navigate('/')} />
			</div>
		</UfinetSectionBox>
	)
}

export { ActionButtons }

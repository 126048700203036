import { useMutation } from 'react-query'

import { ServicesCustomerRepository } from '../domain/repository/ServicesCustomerRepository'
import { ServicesCustomer } from '../domain/ServicesCustomer'
import { ServicesCustomerFindRequest } from './dto/ServicesCustomerFindRequest'

export const useCorporateServicesCustomersFindAllQuery = (
	servicesCustomerRepository: ServicesCustomerRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (data: ServicesCustomer) => void
		onError?: (error: unknown) => void
	}
) => {
	return useMutation((request: ServicesCustomerFindRequest) => servicesCustomerRepository.corporateFindAll(request), {
		onSuccess,
		onError,
		mutationKey: 'services-customer-corporate-find-all',
	})
}

export const useFtthServicesCustomersFindAllQuery = (
	servicesCustomerRepository: ServicesCustomerRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (data: ServicesCustomer) => void
		onError?: (error: unknown) => void
	}
) => {
	return useMutation((request: ServicesCustomerFindRequest) => servicesCustomerRepository.ftthFindAll(request), {
		onSuccess,
		onError,
		mutationKey: 'services-customer-ftth-find-all',
	})
}

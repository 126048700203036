import { AppLanguage } from 'ufinet-web-functions'

export class ServicesCustomerFindRequest {
	customersId: string[] | undefined
	language: AppLanguage
	searchKeyword?: string

	constructor(customersId: string[] | undefined, language: AppLanguage, searchKeyword?: string) {
		this.customersId = customersId
		this.language = language
		this.searchKeyword = searchKeyword
	}

	static fromValues(
		customersId: string[],
		language: AppLanguage,
		searchKeyword?: string,
		isInternal?: boolean
	): ServicesCustomerFindRequest {
		if (isInternal) {
			return new ServicesCustomerFindRequest(undefined, language, searchKeyword ?? '')
		}
		return new ServicesCustomerFindRequest(customersId, language, searchKeyword ?? '')
	}
}

export class Client {
	id: string
	name: string
	cantonId: string
	corporateGroupId: string
	countryId: string
	kamId: string | null
	taxNumber: string | null
	discountPercentageMrc: string | null
	discountPercentageNrc: string | null
	lastModifiedBy: string | null
	lastModifiedAt: string | null

	constructor(
		id: string,
		name: string,
		cantonId: string,
		corporateGroupId: string,
		countryId: string,
		kamId: string | null,
		taxNumber: string | null,
		discountPercentageMrc: string | null,
		discountPercentageNrc: string | null,
		lastModifiedBy: string | null,
		lastModifiedAt: string | null
	) {
		this.id = id
		this.name = name
		this.cantonId = cantonId
		this.corporateGroupId = corporateGroupId
		this.countryId = countryId
		this.kamId = kamId
		this.taxNumber = taxNumber
		this.discountPercentageMrc = discountPercentageMrc
		this.discountPercentageNrc = discountPercentageNrc
		this.lastModifiedBy = lastModifiedBy
		this.lastModifiedAt = lastModifiedAt
	}

	static mapClientsToArray = (clients: Client[]): string[] => {
		return clients.map((client) => client.id)
	}
}

import { useQuery } from 'react-query'

import { Client } from '../domain/Client'
import { ClientsRepository } from '../domain/repository/ClientsRepository'

export const useClientsFindAllQuery = (
	clientsRepository: ClientsRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (clients: Client[]) => void
		onError?: () => void
	} = {}
) => {
	return useQuery('clients-find-all-query', () => clientsRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}

import { ChangeEvent, FC, useCallback } from 'react'

import { FormikProps } from 'formik'
import { onFormikChanges, onFormikTextChanges, useTranslator } from 'ufinet-web-functions'

import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { UfinetButton, UfinetInput, UfinetModal } from 'ufinet-web-components'

type FormikValues = {
	subject: string
	description: string
	files: File[]
}

type NotesModalProps = {
	show: boolean
	handleClose: () => void
	formik: FormikProps<FormikValues>
}

const NotesModal: FC<NotesModalProps> = ({ show, handleClose, formik }) => {
	const { values, errors, isValid, dirty, handleSubmit } = formik
	const translate = useTranslator()
	const onTextChange = useCallback(onFormikTextChanges, [])
	const onChange = useCallback(onFormikChanges, [])

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const selectedFiles: File[] = e.target.files ? Array.from(e.target.files) : []
		const unStoredFiles = selectedFiles.filter((file) => !values.files.some((f) => f.name === file.name))
		if (unStoredFiles.length === 0) return
		onChange(formik, 'files')([...values.files, ...unStoredFiles])
	}

	return (
		<UfinetModal
			size="lg"
			title={translate('TICKET_DETAILS.COMMENTS_MODAL_TITLE')}
			show={show}
			handleClose={handleClose}
		>
			<UfinetInput
				type="text"
				value={values.subject}
				id="subject"
				requiredIcon
				solid={false}
				error={errors.subject}
				labelTitle={translate('TICKET_DETAILS.SUBJECT')}
				className="mb-3 w-100"
				onChange={onTextChange(formik, 'subject')}
			/>

			{/*//TODO: change with textarea ufinet*/}
			<div className="mb-5 w-100">
				<label className="form-label">
					{translate('TICKET_DETAILS.COMMENT')} <span className="error-text">*</span>
				</label>
				<textarea
					className={`textarea-field ${errors.description ? 'textarea-error' : ''}`}
					value={values.description}
					onChange={onTextChange(formik, 'description')}
					rows={5}
				/>
				{errors.description && <div className="error-message">{errors.description}</div>}
			</div>

			<div>
				{values.files.length > 0 && (
					<ul className="list-unstyled">
						{values.files.map((file, index) => (
							<li className="d-flex align-items-center justify-content-between p-1" key={index}>
								<div className="ufinet-color-text">{file?.name}</div>
								<UfinetButton
									icon={faTrash}
									style={{
										backgroundColor: 'red',
										color: '#fff',
										padding: '3px 12px',
										margin: 0,
									}}
									className="d-flex justify-content-center align-items-center"
									onClick={() => onChange(formik, 'files')(values.files.filter((f) => f !== file))}
								/>
							</li>
						))}
					</ul>
				)}
			</div>

			{errors.files && <div className="error-message">{errors.files}</div>}

			<div className="d-flex gap-2 justify-content-end">
				<div>
					<UfinetButton
						content={translate('TICKET_DETAILS.ADD_FILES')}
						onClick={() => {
							const fileInput = document?.getElementById('file-input')
							if (fileInput) fileInput.click()
						}}
					/>
					<input
						id="file-input"
						type="file"
						style={{ display: 'none' }}
						multiple
						onChange={(e) => handleFileChange(e)}
					/>
				</div>

				<UfinetButton
					content={translate('BUTTON.SEND')}
					onClick={() => handleSubmit()}
					type="submit"
					isDisabled={!(isValid && dirty)}
				/>
				<UfinetButton content={translate('BUTTON.CANCEL')} onClick={handleClose} outline />
			</div>
		</UfinetModal>
	)
}

export { NotesModal }

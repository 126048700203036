import { DataTablePFSEvent } from 'primereact/datatable'

import { FormikValues } from 'formik'
import { PROJECT_TYPES_IDS } from 'src/utils/constants'
import { customISOString } from 'src/utils/dateUtils'

function toValueStringArray(item: []) {
	return item?.map((c) => c.value)
}

type Permissions = {
	corporate: boolean
	ftth: boolean
}

export class IncidentFindRequest {
	user: string | undefined
	language: string
	countryId: string
	corporateGroupId: string
	customersId: string[]
	dateFrom: string | undefined
	dateTo: string | undefined
	pageNumber: number
	rowsPerPage: number
	sortField: string | undefined
	sortOrder: number | undefined
	searchKeyword: string | undefined
	projectTypesId: string[] | undefined
	reportTypesId: string[] | undefined
	reportOriginsId: string[] | undefined
	affectationTypesId: string[] | undefined
	tableCountriesId: string | undefined
	tableCustomersId: string | undefined
	degradationTypesId: string[] | undefined
	serviceTypesId: string[] | undefined
	incidentStatuesId: string[] | undefined
	solutionTypesId: string[] | undefined
	primaryCausesId: string | undefined
	secondaryCausesId: string | undefined
	imputableTo: string | undefined
	rfoSentStatuses: string[] | undefined
	ticketNumber: string | undefined

	constructor(
		user: string | undefined,
		language: string,
		countryId: string,
		corporateGroupId: string,
		customersId: [],
		dateFrom: Date | undefined,
		dateTo: Date | undefined,
		pageNumber: number,
		rowsPerPage: number,
		sortField: string | undefined,
		sortOrder: number | undefined,
		searchKeyword: string | undefined,
		projectTypesId: string[] | undefined,
		reportTypesId: string[] | undefined,
		reportOriginsId: string[] | undefined,
		affectationTypesId: string[] | undefined,
		tableCountryId: string | undefined,
		tableClientId: string | undefined,
		degradationTypesId: string[] | undefined,
		serviceTypesId: string[] | undefined,
		incidentStatuesId: string[] | undefined,
		solutionTypesId: string[] | undefined,
		mainCause: string | undefined,
		secondaryCause: string | undefined,
		imputableTo: string | undefined,
		rfoSentStatuses: string[] | undefined,
		ticketNumber: string | undefined
	) {
		this.user = user
		this.language = language
		this.countryId = countryId
		this.corporateGroupId = corporateGroupId
		this.customersId = toValueStringArray(customersId)
		this.dateFrom = customISOString(dateFrom)
		this.dateTo = customISOString(dateTo)
		this.pageNumber = pageNumber
		this.rowsPerPage = rowsPerPage
		this.sortField = sortField
		this.sortOrder = sortOrder
		this.searchKeyword = searchKeyword
		this.projectTypesId = projectTypesId
		this.reportTypesId = reportTypesId
		this.reportOriginsId = reportOriginsId
		this.affectationTypesId = affectationTypesId
		this.tableCountriesId = tableCountryId
		this.tableCustomersId = tableClientId
		this.degradationTypesId = degradationTypesId
		this.serviceTypesId = serviceTypesId
		this.incidentStatuesId = incidentStatuesId
		this.solutionTypesId = solutionTypesId
		this.primaryCausesId = mainCause
		this.secondaryCausesId = secondaryCause
		this.imputableTo = imputableTo
		this.rfoSentStatuses = rfoSentStatuses
		this.ticketNumber = ticketNumber
	}

	static fromValues(
		isInternal: boolean,
		user: string | undefined,
		language: string,
		filter: FormikValues,
		tablePFSEvent: DataTablePFSEvent | undefined,
		permissions: Permissions
	) {
		const sortOptions = {
			sortField: tablePFSEvent?.sortField || '',
			sortOrder: tablePFSEvent?.sortOrder || 0,
			multiSortMeta: tablePFSEvent?.multiSortMeta || [],
		}
		const tableFilter = {
			...(tablePFSEvent?.filters || {}),
		}

		let projectTypesId = tableFilter.projectTypesId?.value

		if (permissions.ftth && !permissions.corporate) {
			projectTypesId = [PROJECT_TYPES_IDS.FTTH]
		} else if (!permissions.ftth && permissions.corporate) {
			projectTypesId = [PROJECT_TYPES_IDS.CORPORATE]
		}

		return new IncidentFindRequest(
			isInternal ? undefined : user,
			language,
			filter.countryId?.value,
			filter.corporateGroupId?.value,
			filter.customersId,
			filter.dateFrom,
			filter.dateTo,
			tablePFSEvent?.page || 0,
			tablePFSEvent?.rows || 10,
			sortOptions.sortField || 'createdDate',
			sortOptions.sortOrder || -1,
			tableFilter.global?.value,
			projectTypesId,
			tableFilter.reportTypesId?.value,
			tableFilter.reportOriginsId?.value,
			tableFilter.affectationTypesId?.value,
			tableFilter.tableCountryId?.value,
			tableFilter.tableClientId?.value,
			tableFilter.degradationTypesId?.value,
			tableFilter.serviceTypesId?.value,
			tableFilter.incidentStatuesId?.value,
			tableFilter.solutionTypesId?.value,
			tableFilter.mainCause?.value,
			tableFilter.secondaryCause?.value,
			tableFilter.imputableTo?.value,
			tableFilter.rfoSentStatuses?.value,
			tableFilter.ticketNumber?.value
		)
	}
}

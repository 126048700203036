import { Dispatch, FC, SetStateAction, useCallback, useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { FormikProps } from 'formik'
import { useDegradationTypeFindAllQuery } from 'src/modules/degradationTypes/application/DegradationTypeQueries'
import { DegradationType } from 'src/modules/degradationTypes/domain/DegradationType'
import { HttpDegradationTypeRepository } from 'src/modules/degradationTypes/infrastructure/HttpDegradationTypeRepository'
import { useReportTypeFindAllQuery } from 'src/modules/reportTypes/application/ReportTypeQueries'
import { ReportType } from 'src/modules/reportTypes/domain/ReportType'
import { HttpReportTypeRepository } from 'src/modules/reportTypes/infrastructure/HttpReportTypeRepository'
import { REPORT_TYPES_IDS } from 'src/utils/constants'
import {
	AuthContext,
	emptyUfinetSelectOption,
	IUfinetSelectOption,
	onFormikChanges,
	onFormikTextChanges,
	useLang,
	useTranslator,
} from 'ufinet-web-functions'

import { DatePicker, DatePickerTypeEnum, UfinetInput, UfinetSectionBox, UfinetSelect } from 'ufinet-web-components'
import { CreateCorporateIncidentFormikType } from '../CreateIncidentFormikType'

interface IncidentInfoSectionCorporateProps {
	formik: FormikProps<CreateCorporateIncidentFormikType>
	selectedReportType?: IUfinetSelectOption
	setSelectedReportType: Dispatch<SetStateAction<IUfinetSelectOption<string> | undefined>>
}

const IncidentInfoSectionCorporate: FC<IncidentInfoSectionCorporateProps> = ({
	formik,
	selectedReportType,
	setSelectedReportType,
}) => {
	const [reportTypes, setReportTypes] = useState<IUfinetSelectOption[]>()
	const [degradationTypes, setDegradationTypes] = useState<IUfinetSelectOption[]>()

	const authData = useContext(AuthContext)
	const translate = useTranslator()
	const language = useLang()
	const onChange = useCallback(onFormikChanges, [])
	const onTextChange = useCallback(onFormikTextChanges, [])
	const nowDate = useMemo(() => new Date(), [])

	const reportTypeRepository = useMemo(() => HttpReportTypeRepository(authData), [authData])
	const degradationTypeRepository = useMemo(() => HttpDegradationTypeRepository(authData), [authData])

	const { isLoading: loadingReportTypes } = useReportTypeFindAllQuery(reportTypeRepository, {
		onSuccess: (params: ReportType[]): void => {
			const mappedData = params.map((item) => ReportType.mapReportTypeToSelectOption(item, language))
			setReportTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.REPORT_TYPE')),
	})
	const { isLoading: loadingDegradationTypes } = useDegradationTypeFindAllQuery(degradationTypeRepository, {
		onSuccess: (params: DegradationType[]): void => {
			const mappedData = params.map((item) => DegradationType.mapDegradationTypeToSelectOption(item, language))
			setDegradationTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.DEGRADATION_TYPE')),
	})

	return (
		<UfinetSectionBox title="incident_info" className="mb-5 p-5">
			<h4>{translate('TICKET.NEW.TITLE.INCIDENT.INFO')}</h4>
			<div className="row">
				<UfinetInput
					type="text"
					value={formik.values.internalCustomerTicketNumber}
					error={formik.errors.internalCustomerTicketNumber}
					id="internal_ticket_number"
					solid={false}
					labelTitle={translate('TICKET.NEW.INTERNAL_TICKET_NUMBER')}
					className="col-12 col-md-6 mt-3"
					onChange={onTextChange(formik, 'internalCustomerTicketNumber')}
				/>
				<UfinetSelect
					options={reportTypes}
					value={formik.values.reportType}
					id="report_type"
					labelTitle={translate('TICKET.NEW.REPORT.TYPE')}
					requiredIcon
					className="col-12 col-md-6 mt-3"
					isLoadingOptions={loadingReportTypes}
					onChange={(e) => {
						onChange(formik, 'reportType')(e)
						onChange(formik, 'degradationType')(emptyUfinetSelectOption)
						setSelectedReportType(e as IUfinetSelectOption)
					}}
					error={formik.errors.reportType?.label}
				/>
			</div>
			<div className="row">
				<UfinetSelect
					options={degradationTypes}
					value={formik.values.degradationType}
					id="degradation_type"
					labelTitle={translate('TICKET.NEW.DEGRADATION_TYPE')}
					requiredIcon={selectedReportType?.value == REPORT_TYPES_IDS.DEGRADATION.toString()}
					className="col-12 col-md-6 mt-3"
					isLoadingOptions={loadingDegradationTypes}
					isDisabled={formik.values.reportType.value !== REPORT_TYPES_IDS.DEGRADATION}
					onChange={onChange(formik, 'degradationType')}
					error={formik.errors.degradationType?.label}
				/>
				<DatePicker
					type={DatePickerTypeEnum.DATE_TIME}
					value={formik.values.detectionTimestamp}
					timeFormat="24"
					id="detection_timestamp"
					label={translate('TICKET.NEW.DETECTION_TIMESTAMP')}
					labelIconRequired
					className="col-12 col-md-6 mt-3"
					onChange={onTextChange(formik, 'detectionTimestamp')}
					error={`${formik.errors.detectionTimestamp || ''}`}
					max={nowDate}
				/>
			</div>
			<div className="row">
				<UfinetInput
					type="text"
					value={formik.values.incidentDescription}
					id="incident_description"
					labelTitle={translate('TICKET.NEW.INCIDENT_DESCRIPTION')}
					requiredIcon
					solid={false}
					className="col mt-3"
					onChange={onTextChange(formik, 'incidentDescription')}
					error={formik.errors.incidentDescription}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { IncidentInfoSectionCorporate }

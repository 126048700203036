import { useMutation, useQuery } from 'react-query'

import { IncidentRepository } from '../domain/repository/IncidentRepository'
import { CreateIncidentActivityDTO } from './dto/CreateIncidentActivityDTO'
import { IncidentActivityFindRequest } from './dto/IncidentActivityFindRequest'
import { IncidentActivityFindResponse } from './dto/IncidentActivityFindResponse'

export const useFindIncidentActivitiesQuery = (
	incidentRepository: IncidentRepository,
	request: IncidentActivityFindRequest,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (response: IncidentActivityFindResponse) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('incident-find-activities-query', () => incidentRepository.findIncidentActivities(request), {
		staleTime: 0,
		onSuccess,
		onError,
		enabled: false,
		refetchOnMount: false,
		retryOnMount: false,
	})
}

export const useCreateIncidentActivityQuery = (
	incidentRepository: IncidentRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useMutation((activity: CreateIncidentActivityDTO) => incidentRepository.createIncidentActivity(activity), {
		onSuccess,
		onError,
		mutationKey: 'incident-create-activity-query',
	})
}

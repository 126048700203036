import { FC, useCallback, useContext, useMemo } from 'react'
import { QueryObserverResult } from 'react-query'
import { toast } from 'react-toastify'

import { useFormik } from 'formik'
import { IncidentActivityFindResponse } from 'src/modules/incidents/application/dto/IncidentActivityFindResponse'
import { IncidentVisitSaveRequest } from 'src/modules/incidents/application/dto/IncidentVisitSaveRequest'
import { useSaveIncidentVisitMutation } from 'src/modules/incidents/application/IncidentVisitSaveQueries'
import { HttpIncidentRepository } from 'src/modules/incidents/infrastructure/HttpIncidentRepository'
import { VALID_TICKET_STATUS_IDS } from 'src/utils/constants'
import { AuthContext, onFormikTextChanges, useTranslator } from 'ufinet-web-functions'

import { DatePicker, DatePickerTypeEnum, UfinetButton, UfinetInput, UfinetSectionBox } from 'ufinet-web-components'

interface DetailTicketIncidentInfoProps {
	visitDate: string | null
	visitContactName: string | null
	visitContactPhone: string | null
	visitRequirements: string | null
	ticketStatusId: number
	incidentId: string
	onCreateComment: () => Promise<QueryObserverResult<IncidentActivityFindResponse, unknown>>
	internalExternalId: { incidentId: string | null; externalId: string | null }
	isCreatingIncidentActivity: boolean
}

const DetailTicketVisitInfo: FC<DetailTicketIncidentInfoProps> = ({
	visitDate,
	visitContactName,
	visitContactPhone,
	visitRequirements,
	ticketStatusId,
	incidentId,
	onCreateComment,
	internalExternalId,
	isCreatingIncidentActivity,
}) => {
	const translate = useTranslator()
	const onTextChange = useCallback(onFormikTextChanges, [])
	const nowDate = useMemo(() => new Date(), [])
	const authData = useContext(AuthContext)
	const incidentRepository = useMemo(() => HttpIncidentRepository(authData), [authData])

	const checkVisitDate = (date: string | null) => {
		if (date) {
			const match = date.match(/\d+/g)
			if (match) {
				const [dia, mes, año, hora, minutos] = match
				return new Date(`${año}-${mes}-${dia}T${hora}:${minutos}:00`)
			}
		}
		return undefined as unknown as Date
	}

	const { mutate: updateVisit, isLoading } = useSaveIncidentVisitMutation(incidentRepository, {
		onSuccess: () => {
			onCreateComment()
			toast.success(translate('TICKET_DETAILS.UPDATE_VISIT_SUCCESS'))
		},
		onError: () => toast.error(translate('TICKET_DETAILS.UPDATE_VISIT_ERROR')),
	})
	const formik = useFormik({
		initialValues: {
			visitDate: checkVisitDate(visitDate),
			visitContactName: visitContactName ?? '',
			visitContactPhone: visitContactPhone ?? '',
			visitRequirements: visitRequirements ?? '',
		},
		onSubmit: (values) => {
			const visitDate = new Date(values.visitDate)
			const isValidDate = !isNaN(visitDate.valueOf())
			const request = {
				visit: IncidentVisitSaveRequest.fromValues({
					...internalExternalId,
					...values,
					visitDate: isValidDate ? values.visitDate : undefined,
					subject: translate('TICKET_DETAILS.UPDATE_VISIT_COMMENT'),
					description: translate('TICKET_DETAILS.UPDATE_VISIT_COMMENT_DESCRIPTION'),
				}),
				incidentId,
			}

			updateVisit(request)
		},
	})

	return (
		<UfinetSectionBox title="visit_info" className="mb-5 p-5">
			<h4 className="ufinet-color-text">{translate('TICKET.NEW.TITLE.VISIT.INFO')}</h4>
			<form onSubmit={formik.handleSubmit}>
				<div className="row">
					<DatePicker
						type={DatePickerTypeEnum.DATE_TIME}
						timeFormat="24"
						value={formik.values.visitDate}
						id="detail_visit_timestamp"
						label={translate('TICKET.NEW.VISIT.TIMESTAMP')}
						className="col-12 col-md mt-3"
						onChange={onTextChange(formik, 'visitDate')}
						min={nowDate}
						disabled={!VALID_TICKET_STATUS_IDS.includes(ticketStatusId) || isLoading}
					/>
					<UfinetInput
						className="col-12 col-md mt-3"
						labelTitle={translate('TICKET.NEW.VISIT.CONTACT')}
						type="text"
						value={formik.values.visitContactName}
						error={formik.errors.visitContactName}
						solid={false}
						onChange={onTextChange(formik, 'visitContactName')}
						isDisabled={!VALID_TICKET_STATUS_IDS.includes(ticketStatusId) || isLoading}
					/>
					<UfinetInput
						className="col-12 col-md mt-3"
						labelTitle={translate('TICKET.NEW.VISIT.PHONE')}
						type="text"
						value={formik.values.visitContactPhone}
						error={formik.errors.visitContactPhone}
						solid={false}
						onChange={onTextChange(formik, 'visitContactPhone')}
						isDisabled={!VALID_TICKET_STATUS_IDS.includes(ticketStatusId) || isLoading}
					/>
				</div>
				<div className="row">
					<UfinetInput
						className="col-12 col-md mt-3"
						labelTitle={translate('TICKET_DETAILS.REQUIREMENTS')}
						type="text"
						value={formik.values.visitRequirements}
						error={formik.errors.visitRequirements}
						solid={false}
						onChange={onTextChange(formik, 'visitRequirements')}
						isDisabled={!VALID_TICKET_STATUS_IDS.includes(ticketStatusId) || isLoading}
					/>
				</div>
				<div className="d-flex justify-content-end mt-3">
					<UfinetButton
						className="mt-3"
						content={translate('SAVE')}
						type="submit"
						isDisabled={!VALID_TICKET_STATUS_IDS.includes(ticketStatusId) || isLoading || isCreatingIncidentActivity}
					/>
				</div>
			</form>
		</UfinetSectionBox>
	)
}

export { DetailTicketVisitInfo }

import { FC } from 'react'

import { useTranslator } from 'ufinet-web-functions'

import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { IUfinetSelectOption, UfinetButton, UfinetModal } from 'ufinet-web-components'

interface AffectedServicesModalProps {
	affectedServices: IUfinetSelectOption[]
	show: boolean
	handleClose: () => void
	deleteAffectedService: (affectedService: IUfinetSelectOption) => void
}

const AffectedServicesModal: FC<AffectedServicesModalProps> = ({
	affectedServices,
	show,
	handleClose,
	deleteAffectedService,
}) => {
	const translate = useTranslator()

	return (
		<UfinetModal title={translate('TICKET.NEW.AFFECTED_SERVICES')} show={show} handleClose={handleClose} size="xl">
			{affectedServices.map((affectedService, index, arr) => (
				<div
					key={index}
					className={`d-flex align-items-center justify-content-between p-3 ${arr.length - 1 !== index ? 'border-bottom' : ''}`}
				>
					<p className="m-0">{affectedService.label}</p>
					<UfinetButton
						icon={faTrash}
						style={{ backgroundColor: 'red', color: '#fff', width: '40px', height: '40px' }}
						className="d-flex justify-content-center align-items-center"
						onClick={() => deleteAffectedService(affectedService)}
					/>
				</div>
			))}
		</UfinetModal>
	)
}

export { AffectedServicesModal }

export const PROJECT_TYPES_IDS = {
	FTTH: 226530000,
	CORPORATE: 226530001,
}

export const REPORT_TYPES_IDS = {
	DEGRADATION: 912790001,
}

export const AFFECTED_TYPES_IDS = {
	CLIENT_SERVICE: 226530000,
	RED_ELEMENT: 226530001,
}

export const VALID_TICKET_STATUS_IDS = [1, 2, 3, 912790000, 912790001, 912790002, 912790003]

export const RFO_SENT_STATUS = (translate: (key: string) => string) => ({
	NOT_SENT: { label: translate('RFO_SENT_STATUS.NOT_SENT'), value: '0' },
	SENT: { label: translate('RFO_SENT_STATUS.SENT'), value: '1' },
});

import { FC, useCallback, useMemo } from 'react'

import { FormikProps } from 'formik'
import { onFormikTextChanges, useTranslator } from 'ufinet-web-functions'

import { DatePicker, DatePickerTypeEnum, UfinetInput, UfinetSectionBox } from 'ufinet-web-components'
import { CreateCorporateIncidentFormikType } from '../CreateIncidentFormikType'

interface ContactInfoSectionCorporateProps {
	formik: FormikProps<CreateCorporateIncidentFormikType>
}

const ContactInfoSectionCorporate: FC<ContactInfoSectionCorporateProps> = ({ formik }) => {
	const translate = useTranslator()
	const { values } = formik

	const onTextChange = useCallback(onFormikTextChanges, [])
	const nowDate = useMemo(() => new Date(), [])
	return (
		<UfinetSectionBox title="contact_info" className="mb-5 p-5">
			<h4>{translate('TICKET.NEW.TITLE.VISIT.INFO')}</h4>
			<div className="row">
				<DatePicker
					type={DatePickerTypeEnum.DATE_TIME}
					timeFormat="24"
					value={values.visitDate}
					id="visit_timestamp"
					label={translate('TICKET.NEW.VISIT.TIMESTAMP')}
					className="col-12 col-md-4 mt-3"
					onChange={onTextChange(formik, 'visitDate')}
					min={nowDate}
				/>
				<UfinetInput
					type="text"
					value={values.visitContact}
					id="visit_contact"
					solid={false}
					labelTitle={translate('TICKET.NEW.VISIT.CONTACT')}
					className="col-12 col-md-4 mt-3"
					onChange={onTextChange(formik, 'visitContact')}
				/>
				<UfinetInput
					type="text"
					value={values.visitPhone}
					id="visit_phone"
					solid={false}
					labelTitle={translate('TICKET.NEW.VISIT.PHONE')}
					className="col-12 col-md-4 mt-3"
					onChange={onTextChange(formik, 'visitPhone')}
				/>
			</div>
			<div className="row">
				<UfinetInput
					type="text"
					value={values.visitRequirements}
					id="visit_requirements"
					solid={false}
					labelTitle={translate('TICKET.NEW.VISIT.REQUIREMENTS')}
					className="col mt-3"
					onChange={onTextChange(formik, 'visitRequirements')}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { ContactInfoSectionCorporate }

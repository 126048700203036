import { ServicesCustomerDTO } from '../application/dto/ServicesCustomerDTO'

type dataTypes = {
	serviceId: string
	name: string
	administrativeCode: string
	serviceCustomerStatusName: string | null
	customerId: string
	corporateGroupId: string
}
export class ServicesCustomer {
	currentPage: number
	data: dataTypes[]
	hasNext: boolean
	hasPrevious: boolean
	pageSize: number
	totalCount: number
	totalPages: number

	constructor(
		data: dataTypes[],
		currentPage: number,
		hasNext: boolean,
		hasPrevious: boolean,
		pageSize: number,
		totalCount: number,
		totalPages: number
	) {
		this.data = data
		this.currentPage = currentPage
		this.hasNext = hasNext
		this.hasPrevious = hasPrevious
		this.pageSize = pageSize
		this.totalCount = totalCount
		this.totalPages = totalPages
	}

	static mapServicesCustomerToSelectOption(self: ServicesCustomer): ServicesCustomerDTO[] {
		return self.data.map((service) => {
			return {
				value: service.serviceId,
				name: service.administrativeCode,
				label: `${service.name} | ${service.administrativeCode}${service.serviceCustomerStatusName ? ` | ${service.serviceCustomerStatusName}` : ''}`,
				customerId: service.customerId,
				corporativeGroupId: service.corporateGroupId,
			}
		})
	}
}

import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormikProps, useFormik } from 'formik'
import { CorporateIncidentsSaveRequest } from 'src/modules/incidents/application/dto/CorporateIncidentsSaveRequest'
import {
	useSaveBulkCorporateIncidentMutation,
	useSaveCorporateIncidentMutation,
} from 'src/modules/incidents/application/SaveCorporateIncidentQueries'
import { HttpIncidentRepository } from 'src/modules/incidents/infrastructure/HttpIncidentRepository'
import { ServicesCustomerDTO } from 'src/modules/servicesCustomer/application/dto/ServicesCustomerDTO'
import { REPORT_TYPES_IDS } from 'src/utils/constants'
import {
	AuthContext,
	emptyUfinetSelectOption,
	IUfinetSelectOption,
	onFormikChanges,
	useTranslator,
} from 'ufinet-web-functions'
import * as Yup from 'yup'

import { UfinetBox } from 'ufinet-web-components'
import { ActionButtons } from './components/ActionButtons'
import { AffectedServicesModal } from './components/AffectedServicesModal'
import { ContactInfoSectionCorporate } from './components/ContactInfoSectionCorporate'
import { IncidentInfoSectionCorporate } from './components/IncidentInfoSectionCorporate'
import { TicketInfoSectionCorporate } from './components/TicketInfoSectionCorporate'
import { CreateCorporateIncidentFormikType } from './CreateIncidentFormikType'

const CreateCorporateIncidentPage: FC = () => {
	const translate = useTranslator()
	const navigate = useNavigate()
	const authData = useContext(AuthContext)
	const onChange = useCallback(onFormikChanges, [])
	const nowDate = useMemo(() => new Date(), [])
	const incidentRepository = useMemo(() => HttpIncidentRepository(authData), [authData])
	const emptyNumberUfinetSelectOption: IUfinetSelectOption<number> = {
		label: '',
		value: 0,
	}
	const [affectedServices, setAffectedServices] = useState<ServicesCustomerDTO[]>([])
	const [selectedReportType, setSelectedReportType] = useState<IUfinetSelectOption>()
	const [showAffectedServicesModal, setShowAffectedServicesModal] = useState<boolean>(false)

	const getDegradationTypeSchemaValidation: () => Yup.ObjectSchema<
		{ label: string | null | undefined } | null,
		Yup.AnyObject,
		{ label: undefined } | null,
		''
	> = () =>
		selectedReportType?.value == REPORT_TYPES_IDS.DEGRADATION.toString()
			? Yup.object()
					.nullable()
					.shape({
						label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
					})
			: Yup.object().nullable().shape({
					label: Yup.string().nullable(),
				})

	const validationSchema = Yup.object().shape({
		reportContactId: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		notificationLang: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		affectedService: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		internalCustomerTicketNumber: Yup.string().max(200, translate('ERROR.MAX_LENGTH')),
		reportType: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		degradationType: getDegradationTypeSchemaValidation(),
		incidentDescription: Yup.string().required(translate('ERROR.REQUIRED')),
		detectionTimestamp: Yup.string().required(translate('ERROR.REQUIRED')),
		reportEmail: Yup.string().required(translate('ERROR.REQUIRED')),
		reportPhone: Yup.string()
			.required(translate('ERROR.REQUIRED'))
			.matches(/^\+?(\d{1,3})?[-. (]*\d{1,4}[-. )]*(\d{1,4}[-. ]*){1,4}\d{1,4}$/, translate('ERROR.PHONE')),
	})

	const { mutate: saveCorporateIncident, isLoading: buttonDisabled } = useSaveCorporateIncidentMutation(
		incidentRepository,
		{
			onSuccess: () => {
				toast.success(translate('TICKET.SAVE.SUCCESS'))
				navigate('/list-tickets')
			},
			onError: () => {
				toast.warning(translate('TICKET.SAVE.ERROR'))
			},
		}
	)

	const { mutate: saveBulkCorporateIncident, isLoading: buttonBulkDisabled } = useSaveBulkCorporateIncidentMutation(
		incidentRepository,
		{
			onSuccess: () => {
				toast.success(translate('TICKETS.SAVE.SUCCESS'))
				navigate('/list-tickets')
			},
			onError: () => {
				toast.warning(translate('TICKETS.SAVE.ERROR'))
			},
		}
	)

	const initialValues = useMemo(
		() => ({
			reportContactId: emptyUfinetSelectOption,
			reportEmail: authData.userData?.username || '',
			notificationGroup: emptyUfinetSelectOption,
			reportPhone: '',
			creationDate: nowDate,
			notificationLang: emptyNumberUfinetSelectOption,
			affectedService: emptyUfinetSelectOption as ServicesCustomerDTO,
			internalCustomerTicketNumber: '',
			reportType: emptyNumberUfinetSelectOption,
			degradationType: emptyNumberUfinetSelectOption,
			detectionTimestamp: undefined as unknown as Date,
			incidentDescription: '',
			visitDate: undefined as unknown as Date,
			visitContact: '',
			visitPhone: '',
			visitRequirements: '',
			countrySelect: emptyUfinetSelectOption,
			corporateGroupSelect: emptyUfinetSelectOption,
			clientSelect: emptyUfinetSelectOption as ServicesCustomerDTO,
			emailUserApplication: authData.userData?.username || '',
		}),
		[authData.userData]
	)

	const formik: FormikProps<CreateCorporateIncidentFormikType> = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: () =>
			affectedServices.length > 1
				? saveBulkCorporateIncident(CorporateIncidentsSaveRequest.fromValues(formik.values, affectedServices))
				: saveCorporateIncident(CorporateIncidentsSaveRequest.fromValues(formik.values, affectedServices)),
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
	})

	const addAffectedService = (affectedService: ServicesCustomerDTO) => {
		if (!affectedServices.find((service) => service.value === affectedService.value)) {
			setAffectedServices([...affectedServices, affectedService])
			toast.success(translate('TICKET.NEW.AFFECTED_SERVICE.ADDED'), { autoClose: 800 })
			return
		}
		toast.error(translate('TICKET.NEW.AFFECTED_SERVICE.EXISTING'), { autoClose: 800 })
	}

	const deleteAffectedService = (affectedService: IUfinetSelectOption) => {
		const newAffectedServices = affectedServices.filter((service) => service.value !== affectedService.value)
		if (newAffectedServices.length === 0) {
			setShowAffectedServicesModal(false)
			onChange(formik, 'reportContactId')({ value: '', label: '' })
			onChange(formik, 'notificationGroup')({ value: '', label: '' })
		}
		setAffectedServices(newAffectedServices)
	}

	return (
		<>
			<UfinetBox>
				<div>
					<TicketInfoSectionCorporate
						formik={formik}
						affectedServices={affectedServices}
						setShowAffectedServicesModal={setShowAffectedServicesModal}
						addAffectedService={addAffectedService}
					/>

					<IncidentInfoSectionCorporate
						formik={formik}
						selectedReportType={selectedReportType}
						setSelectedReportType={setSelectedReportType}
					/>

					<ContactInfoSectionCorporate formik={formik} />

					<ActionButtons onSubmit={formik.handleSubmit} isDisableButton={buttonDisabled || buttonBulkDisabled} />
				</div>
			</UfinetBox>

			<AffectedServicesModal
				affectedServices={affectedServices}
				show={showAffectedServicesModal}
				handleClose={() => setShowAffectedServicesModal(false)}
				deleteAffectedService={deleteAffectedService}
			/>
		</>
	)
}

export { CreateCorporateIncidentPage }

import { format } from 'date-fns'
import dayjs from 'dayjs'

export const convertToLocalTimeString = (date: Date | undefined) => {
	if (date) {
		return format(date, "yyyy-MM-dd'T'HH:mm:ss")
	} else {
		return undefined
	}
}

export const customISOString = (date: Date | undefined) => {
	if (date) {
		return dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
	} else {
		return undefined
	}
}

export const formatDate = (date: string | null, separator: '-' | '/', withTime?: boolean, withSeconds?: boolean) => {
	if (!dayjs(date).isValid()) {
		return ''
	}

	const timeFormat = withSeconds ? 'HH:mm:ss' : 'HH:mm'
	const dateFormat = `DD${separator}MM${separator}YYYY`

	if (withTime) {
		return dayjs(date).format(`${dateFormat} ${timeFormat}`)
	}

	return dayjs(date).format(dateFormat)
}

import { convertToLocalTimeString } from 'src/utils/dateUtils'

import { IncidentVisit } from '../../domain/IncidentVisit'

export class IncidentVisitSaveRequest {
	visit: IncidentVisit

	constructor(visit: IncidentVisit) {
		this.visit = visit
	}

	static fromValues(obj: {
		incidentId: string | null
		externalId: string | null
		visitDate?: Date
		visitContactName: string
		visitContactPhone: string
		visitRequirements: string
		subject: string
		description: string
	}) {
		const visit: IncidentVisit = {
			incidentId: obj.incidentId,
			externalId: obj.externalId,
			visitingHours: convertToLocalTimeString(obj.visitDate),
			visitContactName: obj.visitContactName,
			visitContactPhone: obj.visitContactPhone,
			visitRequirements: obj.visitRequirements,
			subject: obj.subject,
			description: obj.description,
		}
		return new IncidentVisitSaveRequest(visit)
	}
}

import { parseRequestToParams } from 'src/utils/parseRequestToParams'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { ServicesCustomerFindRequest } from '../application/dto/ServicesCustomerFindRequest'
import { ServicesCustomerRepository } from '../domain/repository/ServicesCustomerRepository'
import { ServicesCustomer } from '../domain/ServicesCustomer'

export const HttpServicesCustomerRepository: (authData: IAuthData) => ServicesCustomerRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}servicesCustomer`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		corporateFindAll: async (
			request: ServicesCustomerFindRequest,
			options?: FetchOptions
		): Promise<ServicesCustomer> => {
			const params = parseRequestToParams(request)

			return await FetchAPI.get_params(
				`${url}/GetCorporateServicesCustomer?${params.toString()}`,
				{ rowsPerPage: 50, pageNumber: 0 },
				{
					...fetchOptions,
					...options,
				}
			)
		},
		ftthFindAll: async (request: ServicesCustomerFindRequest, options?: FetchOptions): Promise<ServicesCustomer> => {
			const params = parseRequestToParams(request)

			return await FetchAPI.get_params(
				`${url}/GetFtthServicesCustomer?${params.toString()}`,
				{ rowsPerPage: 50, pageNumber: 0 },
				{
					...fetchOptions,
					...options,
				}
			)
		},
	}
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

import { formatDate } from 'src/utils/dateUtils'

import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

type NoteProps = {
	date: string
	to: string
	subject: string
	content: string
	createdBy: string
	isExternal: boolean
	isFirstNote: boolean
	isLastNote: boolean
}

const Note: FC<NoteProps> = ({
	date,
	to,
	subject,
	content,
	createdBy,
	//  isExternal,
	isFirstNote,
	isLastNote,
}) => {
	return (
		<div className={`note-container row m-0 ${isFirstNote ? 'mt-5' : ''} ${isLastNote ? 'mb-10' : 'mb-5'}`}>
			<div className="col-3 d-flex flex-column align-items-start">
				<div className="note-date">{formatDate(date, '/', true)}</div>
			</div>
			<div className="col-9 d-flex flex-column">
				<div className="note-header d-flex align-items-center">
					<span>{`Ticketing Ufinet`}</span>
					<FontAwesomeIcon icon={faLongArrowAltRight} fontSize={20} className="mx-3" />
					<span>{to}</span>
				</div>
				<div className="note-subject mt-3">{subject}</div>
				<div className="note-content mt-2">{content}</div>
				<div className="note-footer mt-3">{createdBy}</div>
			</div>
		</div>
	)
}

export { Note }

import { useMutation } from 'react-query'

import { IncidentRepository } from '../domain/repository/IncidentRepository'
import { CorporativeIncidentFindResponse } from './dto/CorporativeIncidentFindResponse'

export const useFindCorporateIncidentQuery = (
	incidentRepository: IncidentRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (response: CorporativeIncidentFindResponse) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useMutation((id: string) => incidentRepository.findCorporateIncident(id), {
		onSuccess,
		onError,
		mutationKey: 'corporate-incident-find-query',
	})
}

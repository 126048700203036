import { useMutation } from 'react-query'

import { IncidentRepository } from '../domain/repository/IncidentRepository'
import { FTTHIncidentFindResponse } from './dto/FTTHIncidentFindResponse'

export const useFindFTTHIncidentQuery = (
	incidentRepository: IncidentRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (response: FTTHIncidentFindResponse) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useMutation((id: string) => incidentRepository.findFTTHIncident(id), {
		onSuccess,
		onError,
		mutationKey: 'ftth-incident-find-query',
	})
}

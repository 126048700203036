import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { FormikProps } from 'formik'
import { useClientsFindAllQuery } from 'src/modules/clients/application/ClientsQueries'
import { Client } from 'src/modules/clients/domain/Client'
import { HttpClientsRepository } from 'src/modules/clients/infrastructure/HttpClientsRepository'
import { useNotificationGroupFindAllQuery } from 'src/modules/notificationGroup/application/NotificationGroupQueries'
import { NotificationGroup } from 'src/modules/notificationGroup/domain/NotificationGroup'
import { HttpNotificationGroupRepository } from 'src/modules/notificationGroup/infrastructure/HttpNotificationGroupRepository'
import { ServicesCustomerFindRequest } from 'src/modules/servicesCustomer/application/dto/ServicesCustomerFindRequest'
import { useFtthServicesCustomersFindAllQuery } from 'src/modules/servicesCustomer/application/ServicesCustomerQueries'
import { ServicesCustomer } from 'src/modules/servicesCustomer/domain/ServicesCustomer'
import { HttpServicesCustomerRepository } from 'src/modules/servicesCustomer/infrastructure/HttpServicesCustomerRepository'
import {
	AuthContext,
	IUfinetSelectOption,
	onFormikChanges,
	onFormikTextChanges,
	useInternalUser,
	useLang,
	useTranslator,
} from 'ufinet-web-functions'

import {
	ContactSelect,
	ContactSelectHandle,
	DatePicker,
	DatePickerTypeEnum,
	UfinetInput,
	UfinetSectionBox,
	UfinetSelect,
} from 'ufinet-web-components'
import { useNotificationLanguageFindAllQuery } from '../../../../modules/notificationLanguages/application/NotificationLanguageQueries'
import { NotificationLanguage } from '../../../../modules/notificationLanguages/domain/NotificationLanguage'
import { HttpNotificationLanguageRepository } from '../../../../modules/notificationLanguages/infrastructure/HttpNotificationLanguageRepository'
import { CreateFtthIncidentFormikType } from '../CreateIncidentFormikType'

interface TicketInfoSectionProps {
	formik: FormikProps<CreateFtthIncidentFormikType>
}

const TicketInfoSection: FC<TicketInfoSectionProps> = ({ formik }) => {
	const translate = useTranslator()
	const { values, errors } = formik
	const [inputTimeout, setInputTimeout] = useState<NodeJS.Timeout>()
	const [notificationLangs, setNotificationLangs] = useState<IUfinetSelectOption[]>()
	const [notificationGroups, setNotificationGroups] = useState<IUfinetSelectOption[]>([])
	const [customerId, setCustomerId] = useState<string | undefined>(undefined)
	const [customersId, setCustomersId] = useState<string[]>([])
	const [corporateGroupId, setCorporateGroupId] = useState<string | undefined>(undefined)
	const [servicesCustomer, setServicesCustomer] = useState<IUfinetSelectOption[]>([])

	const contactRef = useRef<ContactSelectHandle>(null)
	const authData = useContext(AuthContext)
	const language = useLang()
	const isInternal = useInternalUser()

	const onChange = useCallback(onFormikChanges, [])
	const onTextChange = useCallback(onFormikTextChanges, [])

	const clientsRepository = useMemo(() => HttpClientsRepository(authData), [authData])
	const servicesCustomerRepository = useMemo(() => HttpServicesCustomerRepository(authData), [authData])
	const notificationLangRepository = useMemo(() => HttpNotificationLanguageRepository(authData), [authData])
	const notificationGroupRepository = useMemo(() => HttpNotificationGroupRepository(authData), [authData])

	useEffect(() => {
		if (customersId.length && customerId === undefined) {
			setServicesCustomer([])
			getServicesCustomer(ServicesCustomerFindRequest.fromValues(customersId, language, undefined, isInternal))
		}
		if (customerId) {
			setServicesCustomer([])
			getServicesCustomer(ServicesCustomerFindRequest.fromValues([customerId], language))
			contactRef.current?.fillSelect({ clientIds: [customerId] })
		}
		if (corporateGroupId && customerId) {
			getNotificationGroups({ corporativeGroupId: corporateGroupId, customerId })
		}
	}, [customersId, customerId, corporateGroupId])

	useClientsFindAllQuery(clientsRepository, {
		onSuccess: (res: Client[]) => setCustomersId(Client.mapClientsToArray(res)),
		onError: () => toast.error(translate('FETCH.ERROR.CLIENT')),
	})

	const { isLoading: loadingNotificationLangs } = useNotificationLanguageFindAllQuery(notificationLangRepository, {
		onSuccess: (params: NotificationLanguage[]): void => {
			const mappedData = params.map((item) =>
				NotificationLanguage.mapNotificationLanguageToSelectOption(item, language)
			)
			setNotificationLangs(mappedData)
		},
	})

	const { mutate: getServicesCustomer, isLoading: loadingServicesCustomer } = useFtthServicesCustomersFindAllQuery(
		servicesCustomerRepository,
		{
			onSuccess: (res) => {
				const mappedData = ServicesCustomer.mapServicesCustomerToSelectOption(res)
				setServicesCustomer(mappedData)
			},
			onError: () => {
				toast.error(translate('FETCH.ERROR.AFFECTED_SERVICE'))
			},
		}
	)

	const { mutate: getNotificationGroups } = useNotificationGroupFindAllQuery(notificationGroupRepository, {
		onSuccess: (params) => {
			const mappedData = params.map((item) => NotificationGroup.mapNotificationGroupToSelectOption(item))
			setNotificationGroups(mappedData)
		},
		onError: () => {
			toast.error(translate('FETCH.ERROR.NOTIFICATION_GROUP'))
		},
	})

	const handleInputChange = useCallback(
		(global) => {
			if (global) {
				if (inputTimeout) {
					clearTimeout(inputTimeout)
				}

				const timeout = setTimeout(() => {
					if (customerId) {
						getServicesCustomer(ServicesCustomerFindRequest.fromValues([customerId], language, global))
					} else {
						getServicesCustomer(ServicesCustomerFindRequest.fromValues(customersId, language, global, isInternal))
					}
				}, 500)

				setInputTimeout(timeout)
			}
		},
		[customersId, inputTimeout]
	)

	return (
		<UfinetSectionBox title="ticket_info" className="mb-5 p-5">
			<h4>{translate('TICKET.NEW.TITLE.TICKET.INFO')}</h4>
			<div className="row">
				<UfinetSelect
					options={servicesCustomer}
					value={values?.affectedService}
					id="project_code"
					labelTitle={translate('TICKET.NEW.PROJECT_CODE')}
					requiredIcon
					className="col-12 col-md-6 mt-3"
					// @ts-expect-error comment
					onChange={(e: ServicesCustomerDTO) => {
						if (e) {
							onChange(formik, 'affectedService')(e)
							onChange(formik, 'clientSelect')(e)
							setCustomerId(e.customerId)
							setCorporateGroupId(e.corporativeGroupId)
							return
						}

						onChange(formik, 'affectedService')({ value: '', label: '' })
						onChange(formik, 'notificationGroupId')({ value: '', label: '' })
						onChange(formik, 'reportContactPhone')('')
						onChange(formik, 'notificationLanguageId')({ value: '', label: '' })
						setCustomerId(undefined)
						setCorporateGroupId(undefined)
						return
					}}
					onInputChange={handleInputChange}
					error={errors.affectedService?.label}
					isLoadingOptions={loadingServicesCustomer}
					isClearable
					clearValues
				/>

				<ContactSelect
					className="col-12 col-md-6 mt-3"
					ref={contactRef}
					error={formik.errors.reportContactId?.label}
					value={formik.values.reportContactId}
					labelTitle={translate('TICKET.NEW.REPORT.USER')}
					requiredIcon
					onChange={onChange(formik, 'reportContactId')}
					isDisabled={customerId === undefined || corporateGroupId === undefined}
				/>
			</div>
			<div className="row">
				<UfinetInput
					type="text"
					value={values.reportContactEmail}
					id="report_email"
					isDisabled
					requiredIcon
					solid={false}
					error={errors.reportContactEmail}
					labelTitle={translate('TICKET.NEW.REPORT.EMAIL')}
					className="col-12 col-md-6 mt-3"
					onChange={onTextChange(formik, 'reportContactEmail')}
				/>
				<UfinetSelect
					options={notificationGroups}
					value={values.notificationGroupId}
					id="notification_group"
					labelTitle={translate('TICKET.NEW.NOTIFICATION_GROUP')}
					className="col-12 col-md-6 mt-3"
					onChange={onChange(formik, 'notificationGroupId')}
					isDisabled={notificationGroups?.length === 0 || corporateGroupId === undefined}
				/>
			</div>
			<div className="row">
				<UfinetInput
					type="text"
					value={values.reportContactPhone}
					id="report_phone"
					requiredIcon
					solid={false}
					error={errors.reportContactPhone}
					labelTitle={translate('TICKET.NEW.REPORT.PHONE')}
					className="col-12 col-md-4 mt-3"
					onChange={onTextChange(formik, 'reportContactPhone')}
				/>
				<DatePicker
					value={values.creationDate}
					timeFormat="24"
					type={DatePickerTypeEnum.DATE_TIME}
					id="creation_timestamp"
					label={translate('TICKET.NEW.CREATION_DATE')}
					className="col-12 col-md-4 mt-3"
					disabled
					onChange={() => {}}
				/>
				<UfinetSelect
					options={notificationLangs}
					isLoadingOptions={loadingNotificationLangs}
					value={values.notificationLanguageId}
					error={errors.notificationLanguageId?.label}
					id="notification_lang"
					requiredIcon
					labelTitle={translate('TICKET.NEW.NOTIFICATION_LANGUAGE')}
					className="col-12 col-md-4 mt-3"
					onChange={onChange(formik, 'notificationLanguageId')}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { TicketInfoSection }

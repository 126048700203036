import { useMutation } from 'react-query'

import { IncidentRepository } from '../domain/repository/IncidentRepository'
import { CorporateIncidentsSaveRequest } from './dto/CorporateIncidentsSaveRequest'
import { FtthIncidentSaveRequest } from './dto/FtthIncidentSaveRequest'

export const useSaveCorporateIncidentMutation = (
	corporateIncidentRepository: IncidentRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useMutation(
		(request: CorporateIncidentsSaveRequest) => corporateIncidentRepository.saveCorporateIncident(request),
		{
			onSuccess,
			onError,
			mutationKey: 'corporate-incident-save',
		}
	)
}

export const useSaveBulkCorporateIncidentMutation = (
	corporateIncidentRepository: IncidentRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useMutation(
		(request: CorporateIncidentsSaveRequest) => corporateIncidentRepository.saveBulkCorporateIncident(request),
		{
			onSuccess,
			onError,
			mutationKey: 'corporate-incident-save',
		}
	)
}

export const useSaveFtthIncidentMutation = (
	ftthIncidentRepository: IncidentRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useMutation((request: FtthIncidentSaveRequest) => ftthIncidentRepository.saveFtthIncident(request), {
		onSuccess,
		onError,
		mutationKey: 'ftth-incident-save',
	})
}

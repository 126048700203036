import { IncidentActivityFindRequest } from 'src/modules/incidents/application/dto/IncidentActivityFindRequest';
import { IncidentExportRequest } from 'src/modules/incidents/application/dto/IncidentExportRequest';
import { IncidentFindRequest } from 'src/modules/incidents/application/dto/IncidentFindRequest';
import { ServicesCustomerFindRequest } from 'src/modules/servicesCustomer/application/dto/ServicesCustomerFindRequest';

type request = IncidentExportRequest | IncidentFindRequest | IncidentActivityFindRequest | ServicesCustomerFindRequest

export const parseRequestToParams = (request: request): URLSearchParams => {
	const params = new URLSearchParams()
	for (const key in request) {
		const value = request[key as keyof request]
		if (value !== undefined && value !== null && value !== '') {
			if (Array.isArray(value)) {
				(value as string[]).forEach((v: string) => {
					if (v !== undefined && v !== null && v !== '') {
						params.append(key, v)
					}
				})
			} else {
				params.append(key, value as string)
			}
		}
	}
	return params
}
